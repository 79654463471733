import React, { useContext, useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { get, post } from "../../helpers/Network";
import Streamer from "../streamer";
import { PeerContext, VideoContext } from "../../contexts";
import { text } from 'body-parser';
import Game from "../game"
const login_link = `${ process.env.REACT_APP_BACKEND_HOST }/api/auth/google`;

export function Home(props) {
  const [ textInput, setTextInput ] = useState("");
  const [ messageInput, setMessageInput ] = useState("");
  const {     
    peers,
    peerStreams,
    userId,
  } = useContext(PeerContext);

  const {
    VideoStream,
  } = useContext(VideoContext);


  
  const [streams, setStreams] = useState([]);
  useEffect( () => {
    const newStreams = [];
    Object.keys( peers ).forEach( key => {
      const peer = peers[key];
      if  ( peer ) {
        newStreams.push( {
          stream: peer.VideoStream,
          id: key,
          friendly_name: peer.friendly_name
        } );
      }
    })
    setStreams( newStreams );
  },[peerStreams, peers]);

  console.log( streams )

  return (
    <div className="game-wrapper">
      <div className="game-container">
        <Game/>
      </div>
      <div className="stream-container">
        <div className="stream-container__inner">
          <Streamer id="local" friendly_name="You" local_id={userId} VideoStream={VideoStream} muted={true} broadcast={false} />
          {streams.map( peerStream => 
            <Streamer key={peerStream.id} remote_id={peerStream.id}  VideoStream={peerStream.stream} friendly_name={peerStream.friendly_name} />
          )}
        </div>
      </div>
    </div>
  )
}

Home.propTypes = {

}
