/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState, Component } from 'react';
import useChannel from "../../helpers/Websockets"
import VideocamOutline from 'react-ionicons/lib/IosVideocamOutline'
import MicOutline from 'react-ionicons/lib/IosMicOutline'

let broadcastInterval;

function Streamer( props ) {

  const [videoStatusMessage, sendVideoStatusMessage ] = useChannel("videoStatus");
  const [audioStatusMessage, sendAudioStatusMessage ] = useChannel("audioStatus");

  const videoRef = useRef();
  const [MediaStreamId, setMediaStreamId] = useState(null);
  const [RemotePeerID, setRemotePeerID] = useState(null); // if this is set, then it is an incoming stream
  const [LocalID, setLocalID] = useState(null); // if this is set then it is an outgoing stream 
  const [VideoTrack, setVideoTrack] = useState(null);
  const [AudioTrack, setAudioTrack] = useState(null);
  const [AudioEnabled, setAudioEnabled ] = useState(null);
  const [VideoEnabled, setVideoEnabled ] = useState(null);

  function mute( force ){
    if ( !AudioTrack ) return;
    const currentState = AudioTrack.enabled;
    let newState = !currentState;
    if ( typeof force === "boolean" ) newState = force;
    if ( newState === currentState ) return;
    AudioTrack.enabled = newState;
    setAudioEnabled( newState );
  }

  function disableVideo( force ){
    if ( !VideoTrack ) return;
    const currentState = VideoTrack.enabled;
    let newState = !currentState;
    if ( typeof force === "boolean" ) newState = force;
    if ( newState === currentState ) return;
    VideoTrack.enabled = newState;
    setVideoEnabled( newState );
  }

  function broadcastStatus(){
    if ( LocalID ) {
      sendAudioStatusMessage({
        type: "instruction",
        payload: {
          from: LocalID,
          status:AudioEnabled, 
        }
      });
      sendVideoStatusMessage({
        type: "instruction",
        payload: {
          from: LocalID,
          status:VideoEnabled, 
        }
      });
    }
  }

  useEffect(()=>{
    broadcastStatus();
  },[AudioEnabled,LocalID]);


  useEffect(()=>{
    broadcastStatus();
  },[VideoEnabled,LocalID]);

  useEffect(()=>{
    if ( LocalID && !broadcastInterval && props.broadcast) {
      broadcastInterval = setInterval(() => {
        broadcastStatus()
      }, 3000);
    }
    return ()=>{
      if( broadcastInterval ) {
        clearInterval( broadcastInterval );
        broadcastInterval = null;
      }
    }
  },[LocalID]);

  useEffect(()=>{
    if ( audioStatusMessage && audioStatusMessage.payload ) {
      const { type } = audioStatusMessage;
      const { from, status, target } = audioStatusMessage.payload;
      if ( type === "instruction" && RemotePeerID){
        if ( from === RemotePeerID && typeof status === "boolean" ) {
          mute( status );
        }
      }
    }
  },[audioStatusMessage,RemotePeerID,LocalID])

  useEffect(()=>{
    if ( videoStatusMessage && videoStatusMessage.payload ) {
      const { type } = videoStatusMessage;
      const { from, status, target } = videoStatusMessage.payload;
      if ( type === "instruction" && RemotePeerID ){
        if ( from === RemotePeerID && typeof status === "boolean" ) {
          disableVideo( status );
        }
      }
    }
  },[videoStatusMessage,RemotePeerID])

  function updateVideoStream() {
    if ( props.friendly_name ) {
      window.streams[props.friendly_name] = props;
    }
    if (props.VideoStream && videoRef.current.srcObject !== props.VideoStream ) {
      if ( MediaStreamId !== props.VideoStream.id ) {
        videoRef.current.srcObject = props.VideoStream
        const videoTrack = props.VideoStream.getVideoTracks()[0] || null ;
        const audioTrack = props.VideoStream.getAudioTracks()[0] || null ;
        if ( videoTrack ) setVideoEnabled(videoTrack.enabled);
        if ( audioTrack ) setAudioEnabled(audioTrack.enabled);
        setMediaStreamId(props.VideoStream.id);
        setVideoTrack(videoTrack);
        setAudioTrack(audioTrack);
      }
    }
    if( props.local_id ) {
      setLocalID(props.local_id);
    } else if( props.remote_id ) {
      setRemotePeerID(props.remote_id);
    }
  }

  useEffect(()=>{
    if (!window.streams) {
      window.streams = {};
    } 
    updateVideoStream()
  },[props]);
  
    return (
      <div className="video-streamer">
        {props && props.friendly_name && (
          <span className="video-streamer__label">
            {props.friendly_name}
          </span>
        ) }
        {props && !props.friendly_name && !props.VideoStream && (
          <span className="video-streamer__label">
            Loading
          </span>
        ) }
        {props && props.VideoStream && (
          <>
            <button onClick={mute} className={`video-streamer__controls video-streamer__controls--audio ${!!RemotePeerID ? "video-streamer__controls--remote" : ""} ${!AudioEnabled ? "video-streamer__controls--disabled" : ""}`}><MicOutline color="white"/></button>
            <button onClick={disableVideo} className={`video-streamer__controls video-streamer__controls--video ${!!RemotePeerID ? "video-streamer__controls--remote" : ""} ${!VideoEnabled ? "video-streamer__controls--disabled": ""}`}><VideocamOutline color="white"/></button>
          </>
        ) }
        <video autoPlay={true} ref={ videoRef } muted={props.muted}></video>
      </div>
    )

}

export default Streamer

