import React, { useState, useEffect }from 'react'
import FrontCap from "./front-cap.png"
import EndCap from "./end-cap.png"


export default function GameCard({ card, withAnswers, category }) {
  let classes = "game-card";
  const [cardKey, setCardKey] = useState("question");
  if ( withAnswers ) {
    classes += " game-card--with-answers"
  }

  function toggleAnswers() {
    if ( withAnswers ) {
      if ( cardKey === "question" ) return setCardKey("answer");
      if ( cardKey === "answer" ) return setCardKey("question");
    }
  }

  useEffect(() => {
    setCardKey("question");
  },[card])

  if ( !card ) {
    return false;
  }

  return (
    <div className={classes} onClick={toggleAnswers}>
      <img className="game-card__front-cap" src={String(FrontCap)}></img>
      <ul className={`game-card__list game-card__list--${cardKey}s`}>
        <li className={`game-card__list-item game-card__list-item--word ${category && category === "word" && "highlight"}`}>{card.word[cardKey]}</li>
        <li className={`game-card__list-item game-card__list-item--people ${category && category === "people" && "highlight"}`}>{card.people[cardKey]}</li>
        <li className={`game-card__list-item game-card__list-item--initials ${category && category === "initials" && "highlight"}`}>{card.initials[cardKey]}</li>
        <li className={`game-card__list-item game-card__list-item--movie ${category && category === "movie" && "highlight"}`}>{card.movie[cardKey]}</li>
        <li className={`game-card__list-item game-card__list-item--date ${category && category === "date" && "highlight"}`}>{card.date[cardKey]}</li>
      </ul>
      <img className="game-card__end-cap" src={String(EndCap)}></img>
    </div>
  )
}
