import React, { useState, useRef, useEffect } from 'react'
import GameCard from "../gamecard";
import useChannel from "../../helpers/Websockets";

const phaseOptions = {
  STARTING: 1,
  WRITING: 2,
  GUESSING: 3,
  REVEALING: 4,
}
Object.freeze(phaseOptions);

function randomise( array ) {

}

export default function Leader({passLeadership, getNextCard, card, getPrevCard, peers, userId, calculatePoints }) {
  const [category, setCategory] = useState("");
  const [answer, setAnswer] = useState("");
  const [submissions, setSubmissions] = useState({});
  const [allGuesses, setAllGuessesSubmissions] = useState(null);
  const [shuffledSubmissions, setShuffledSubmissions] = useState([]);
  
  const [phase, setPhase] = useState(phaseOptions.STARTING);
  const categoryInput = useRef();
  const [categoryMessage, sendCategoryMessage] = useChannel("category");
  const [submissionMessage, sendSubmissionMessage] = useChannel("submission");
  const [potentialAnswers, sendPotentialAnswers] = useChannel("potentialAnswers");
  const [guessesMessage, sendGuessesMessage] = useChannel("guesses");
  const [allGuessesMessage, sendAllGuessesMessage] = useChannel("allGuesses");

  useEffect(()=>{
    if ( submissionMessage ) {
      const { payload } = submissionMessage;
      console.log( submissionMessage );
      if ( payload && payload.answer && payload.userId ) {
        const newSubmissions = {...submissions};
        newSubmissions[payload.userId] = payload.answer;
        setSubmissions(newSubmissions);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionMessage])

  useEffect(()=>{
    if ( guessesMessage ) {
      const { payload } = guessesMessage;
      if ( payload && payload.guesses && payload.userId ) {
        const newGuesses = {...allGuesses};
        newGuesses[payload.userId] = payload.guesses;
        setAllGuessesSubmissions(newGuesses);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guessesMessage])

  useEffect(()=>{
    if ( phase === phaseOptions.GUESSING ) {
      let submissionArr = submissionKeys.map( submissionKey => {
        const peer = peers[submissionKey];
        if ( peer ) {
          const { friendly_name } = peer;
          const submission_text = submissions[submissionKey];
          return {
            friendly_name,
            submission_text,
            id: submissionKey,
          }
        }
        return false;
      }).filter( submission => !!submission);
      submissionArr.push({
        friendly_name: "You",
        submission_text:answer,
        id: userId,  
      });
      submissionArr.push({
        friendly_name: "Real",
        submission_text:card[category].answer,
        id: "real",  
      });
      submissionArr = submissionArr.map( submission => {
        submission.order = Math.random();
        return submission;
      }).sort( (a,b) => a.order - b.order );
      setShuffledSubmissions( submissionArr );
      sendPotentialAnswers( { payload: { submissionArr } });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[phase])

  const submissionKeys = Object.keys( submissions );
  const submissionArr = submissionKeys.map( submissionKey => {
    const peer = peers[submissionKey];
    if ( peer ) {
      const { friendly_name } = peer;
      const submission_text = submissions[submissionKey];
      return {
        friendly_name,
        submission_text,
      }
    }
    return false;
  }).filter( submission => !!submission);

  let points = {};
  if ( allGuesses ) {
    points = calculatePoints( allGuesses);
  }

  return (
    <div>
      <h1 className="welcome__header">You're in charge!</h1>
      <GameCard card={card} withAnswers={true} category={category}/>


      { phase === phaseOptions.WRITING ? (
        <div>
          <p>When everyone's submissions are in, press continue.</p>
            <div>
              <h3 className="answer__title">Real Answer</h3> 
              <p className="answer__text">{card && card[category].answer}</p>
            </div>
            {submissionArr.map(sub=>(
              <div>
                <h3 className="answer__title">{sub.friendly_name}</h3> 
                <p className="answer__text">{sub.submission_text}</p>
              </div>
            ))}
            <div>
              <h3 className="answer__title">Your Answer</h3> 
              <textarea className="answer-box" value={answer} onChange={(e)=>{setAnswer(e.target.value)}}></textarea>
            </div>
            <button className={`button-primary button-primary--space ${ submissionArr.length !== Object.keys(peers).length && "disabled"}`}onClick={()=> {
              submissionArr.length === Object.keys(peers).length && setPhase(phaseOptions.GUESSING);
            }}>Continue</button>   
        </div>
      ) : false}

      { phase === phaseOptions.GUESSING ? (
        <div>
          {shuffledSubmissions.map( (submission, i) => (
            <div>
              <h3 className="answer__title">Option {i + 1} ({submission.friendly_name})</h3> 
              <p className="answer__text">{submission.submission_text}</p>
            </div>
          ))}
          <p>When everyone has input their guesses, press continue.</p>
          <button className={`button-primary button-primary--space ${ allGuesses && Object.keys(peers).length === Object.keys(allGuesses).length ? "enabled" : "disabled"}`}onClick={()=> {
            if ( allGuesses && Object.keys(peers).length === Object.keys(allGuesses).length ) {
              setPhase(phaseOptions.REVEALING);
              sendAllGuessesMessage({payload:{allGuesses}});
            }
          }}>Continue</button>   
        </div>
      ): false }

      
      { phase === phaseOptions.REVEALING && points && allGuesses ? (
        <div>
         {Object.keys( points ).map( peerId => {
          return (
            <div>
              {peerId === userId ? 
              <h3>You: {points[peerId]}</h3>
              : 
              <h3>{peers[peerId].friendly_name}: {points[peerId]}</h3>
              }
              { allGuesses[peerId] ? (
                <summary>
                  Guess Breakdown
                  <details>
                    {shuffledSubmissions.map( (submission, i) => (
                      <div>
                        <h3 className="answer__title">Option {i + 1} ({submission.friendly_name})</h3> 
                        <p className="answer__text">{submission.submission_text}</p>
                        { submission.id !== peerId ? 
                          allGuesses[peerId][submission.id] === submission.id ? "Guessed Correctly" : `Guessed it was ${ 
                            peers[allGuesses[peerId][submission.id]] ? peers[allGuesses[peerId][submission.id]].friendly_name : allGuesses[peerId][submission.id] === userId ? "You" : allGuesses[peerId][submission.id]
                          }`
                          : "Their own submission"
                        }
                      </div>
                    ))}
                  </details>
                </summary>
              ) : false }
            </div>
          )
        })} 
          <p>When you're ready to play the next round, click continue</p>
          <button className={`button-primary button-primary--space`}onClick={()=> {
            passLeadership();
          }}>Continue</button>   
        </div>
      ): false }      


      {phase === phaseOptions.STARTING ? (
        <div>
          <button className="button-primary button-primary--space" onClick={passLeadership}>Pass leadership?</button>
          <button className="button-primary button-primary--space" onClick={getPrevCard}>Get prev card</button>
          <button className="button-primary button-primary--space" onClick={getNextCard}>Get next card</button>
          <div>
            <select className="button-primary--space" ref={categoryInput} value={category} onChange={(e)=>{ setCategory(e.target.value) }}>
              <option value="" disabled >Which category should we play?</option>
              <option value="word">Word</option>
              <option value="people">People</option>
              <option value="initials">Initials</option>
              <option value="movie">Movie</option>
              <option value="date">Date</option>
            </select>
            <button className={`button-primary button-primary--space ${!category ? "disabled": ""}`}onClick={()=> {
              if ( !category && categoryInput.current ) {
                categoryInput.current.focus();
              } else {
                sendCategoryMessage({
                  payload: {
                    category
                  },
                  type: "setCategory",
                });
                setPhase(phaseOptions.WRITING);
              }
            }}>Continue</button>    
          </div>
        </div>
      ) : false}
    </div>
  )
}

