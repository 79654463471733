import React, { useState } from "react";

let ws;
let reauthTimeout;
const baseConnectTime = 100;
let connectTime = baseConnectTime;
let unmounting = false;
const channelCounter = 0;
let pingInterval;


export function toEvent( message ) {
  const event = JSON.parse( message.data );
  return {
    channel: event.channel,
    type: event.type,
    payload: event.payload,
    ts: event.ts,
  };
}

function isFunction( obj ) {
  return !!( obj && obj.constructor && obj.call && obj.apply );
}

function eventEmitterGenerator() {
  const callbacks = {};
  const on = ( eventName, callback, key ) => {
    if ( !isFunction( callback ) ) {
      throw new Error( "Callback must be a function!" );
    }
    if ( typeof eventName !== "string" ) {
      throw new Error( "Event name must be a string!" );
    }
    if ( typeof key !== "string" ) {
      throw new Error( "Key must be a string!" );
    }
    if ( !callbacks[ eventName ] ) {
      callbacks[ eventName ] = {};
    }
    callbacks[ eventName ][ key ] = callback;
    return true;
  };
  const off = ( eventName, key ) => {
    if ( typeof key !== "string" ) {
      throw new Error( "Key must be a string!" );
    }
    if ( typeof eventName !== "string" ) {
      throw new Error( "Event name must be a string!" );
    }
    if ( callbacks[ eventName ] && callbacks[ eventName ][ key ] ) {
      delete callbacks[ eventName ][ key ];
      return true;
    }
    return false;
  };
  const emit = ( eventName, arg ) => {
    if ( callbacks[ eventName ] ) {
      Object.values( callbacks[ eventName ] ).forEach( ( callback ) => {
        if ( callback ) callback( arg );
      } );
      return true;
    }
    return false;
  };
  return {
    on,
    emit,
    off,
  };
}
export const Channels = eventEmitterGenerator();

export function send( channel, payload, type ) {
  if ( ws && ws.readyState === ws.OPEN ) {
    ws.send( JSON.stringify(
      {
        payload,
        type,
        channel,
        ts: Date.now(),
      },
    ) );
    return true;
  } else {
    return false;
  }
}

function useChannel( channelName ) {
  const [ message, publishMessage ] = useState( null );

  function sendMessage( msg ) {
    if ( ws && ws.readyState === ws.OPEN ) {
      ws.send( JSON.stringify(
        {
          ...msg,
          channel: channelName,
          ts: Date.now(),
        },
      ) );
      return true;
    } else {
      return false;
    }
  }
  let lastMessage = null;
  const listenerKey = `${ channelName }_${ channelCounter }`;
  Channels.on( channelName, ( msg ) => {
    const {
      type, payload, channel, ts,
    } = msg;
    if ( channel === channelName ) {
      lastMessage = {
        type,
        payload,
        ts,
      };
      publishMessage( lastMessage );
    }
  }, listenerKey );
  const close = () => Channels.off( channelName, listenerKey );
  return [ message, sendMessage, close ];
}

function connectWs() {
  ws = new WebSocket( `${ process.env.REACT_APP_BACKEND_HOST.replace( "http", "ws" ) }/api/sockets` );
  ws.onopen = ( event ) => {
    connectTime = baseConnectTime;
    if ( pingInterval ) {
      clearInterval( pingInterval );
    }
    pingInterval = setInterval(()=>{
      send("ping",{},"client")
    }, 5000 );
  };

  ws.onmessage = ( ev ) => {
    const message = toEvent( ev );
    const { type, channel } = message;
    console.log( channel, message );
    Channels.emit( channel, message );
  };

  ws.onerror = ( ev ) => { console.error( ev ); };
  ws.onclose = ( ev ) => {
    if ( reauthTimeout ) {
      clearTimeout( reauthTimeout );
    }
    if ( unmounting ) return;
    setTimeout( () => {
      connectTime += connectTime;
      if ( connectTime >= 5000 ) {
        connectTime = 5000;
      }
      connectWs();
    }, connectTime );
  };
}

window.addEventListener( "beforeunload", ( event ) => {
  unmounting = true;
  ws.send( JSON.stringify(
    {
      payload: {
        exit: true,
      },
      type: "navigate",
      channel: "useractivity",
      ts: Date.now(),
    },
  ) );
  ws.close();
} );

connectWs();

export default useChannel;
