import React, { useContext, useState, useEffect, createContext } from "react";
import cookies from "../helpers/cookies";

const ConfigContext = createContext();

const SavedConfig = cookies.retrieve("local_config")
let defaultName = "";
if ( SavedConfig && SavedConfig.name ) {
  defaultName = SavedConfig.name;
}

function ConfigContextProvider( props ) {
  
  const [name, setName] = useState(defaultName);
  
  function saveName(newName) {
    let config = {
      name: newName,
    }
    cookies.save("local_config", config);
    setName(newName)
  }

  useEffect( () => {
    const SavedConfig = cookies.retrieve("local_config")
    let defaultName = null;
    if ( SavedConfig && SavedConfig.name ) {
      defaultName = SavedConfig.name;
      setName(defaultName);
    }
  },[])

  const defaultContext = {
    name,
    saveName,
  };

  return (
    <ConfigContext.Provider value={defaultContext}>
      { props.children }
    </ConfigContext.Provider>
  );
}

export {
  ConfigContext,
  ConfigContextProvider,
}