export default function getUserMedia(){
  return new Promise( (resolve, reject) => {
    navigator.getUserMedia({ video: true, audio: process.env.NODE_ENV === "production" }, 
      function( stream ) {
        resolve(stream );
      }, 
      function( error ) {
        reject( error );
      })
  })
} 