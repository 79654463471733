import React, { useState, useEffect } from 'react';
import GameCard from "../gamecard";
import useChannel from "../../helpers/Websockets";
import Leader from './leader';

const phaseOptions = {
  STARTING: 1,
  WRITING: 2,
  GUESSING: 3,
  REVEALING: 4,
}
Object.freeze(phaseOptions);

export default function Player({ card, leaderName, leader, userId, peers, calculatePoints }) {

  const [phase, setPhase] = useState(phaseOptions.STARTING);
  const [category, setCategory] = useState("");
  const [answer, setAnswer] = useState("");
  const [sentAnswer, setSentAnswer] = useState(null);
  const [guesses, setGuesses] = useState({});
  const [allGuesses, setAllGuesses] = useState(null);

  const [shuffledSubmissions, setShuffledSubmissions] = useState([]);

  const [categoryMessage, sendCategoryMessage] = useChannel("category");
  const [submissionMessage, sendSubmissionMessage] = useChannel("submission");
  const [potentialAnswersMessage, sendPotentialAnswersMessage] = useChannel("potentialAnswers");
  const [guessesMessage, sendGuessesMessage] = useChannel("guesses");
  const [allGuessesMessage, sendAllGuessesMessage] = useChannel("allGuesses");

  useEffect(()=>{
    if ( categoryMessage ) {
      const { payload } = categoryMessage;
      if ( payload && payload.category ) {
        setCategory(payload.category);
        setPhase(phaseOptions.WRITING);
      }
    }
  }, [categoryMessage])

  useEffect(()=>{
    if ( potentialAnswersMessage ) {
      const { payload } = potentialAnswersMessage;
      if ( payload && payload.submissionArr ) {
        setShuffledSubmissions(payload.submissionArr);
        setPhase(phaseOptions.GUESSING);
      }
    }
  }, [potentialAnswersMessage])

  useEffect(()=>{
    if ( allGuessesMessage ) {
      const { payload } = allGuessesMessage;
      if ( payload && payload.allGuesses ) {
        setAllGuesses(payload.allGuesses);
      }
    }
  }, [allGuessesMessage])

  let points = {};
  if ( allGuesses ) {
    points = calculatePoints( allGuesses);
  }

  return (
    <div>
      <h1 className="welcome__header">{leaderName} is in charge!</h1>

      { phase === phaseOptions.GUESSING ? (
        <div>
          {shuffledSubmissions.map( (submission, i) => (
            <div>
              <h3 className="answer__title">Option {i+1}</h3> 
              {/* <p className="answer__text">{submission.submission_text}</p> */}
              {submission.id === userId ? <p>Yours</p> : (
                <div>
                  <input type="text"/>
                  <select value={guesses[submission.id] || ""} onChange={e => {
                    const newGuesses = {...guesses};
                    newGuesses[submission.id] = e.target.value;
                    setGuesses(newGuesses);
                    }}>
                    <option value="">Who do you think wrote this?</option>
                    <option value="real" disabled={!!Object.values(guesses).filter(guess => guess === "real").length}>The Real Answer</option>
                    {Object.keys(peers).map( peerId => 
                      <option value={peerId} disabled={!!Object.values(guesses).filter(guess => guess === peerId).length}>{peers[peerId].friendly_name}</option>
                    )}
                  </select>
                </div>
              )}
            </div>
          ))}
          <button onClick={()=>{
            sendGuessesMessage( {payload: { guesses, userId }});
            setPhase(phaseOptions.REVEALING);
            }} className="button-primary button-primary--space">Send</button>
        </div>
      ): false }

      { phase === phaseOptions.REVEALING && points && allGuesses ? (
        <div>
        {Object.keys( points ).map( peerId => {
          return (
            <div>
              {peerId === userId ? 
              <h3>You: {points[peerId]}</h3>
              : 
              <h3>{peers[peerId].friendly_name}: {points[peerId]}</h3>
              }
              { allGuesses[peerId] ? (
                <summary>
                  Guess Breakdown
                  <details>
                    {shuffledSubmissions.map( (submission, i) => (
                      <div>
                        <h3 className="answer__title">Option {i+1} ({submission.friendly_name})</h3> 
                        <p className="answer__text">{submission.submission_text}</p>
                        { submission.id !== peerId ? 
                          allGuesses[peerId][submission.id] === submission.id ? "Guessed Correctly" : `Guessed it was ${                            
                            peers[allGuesses[peerId][submission.id]] ? peers[allGuesses[peerId][submission.id]].friendly_name : allGuesses[peerId][submission.id] === leader ? leaderName : allGuesses[peerId][submission.id]
                          }`
                          : "Their own submission"
                        }
                      </div>
                    ))}
                  </details>
                </summary>
              ) : false }
            </div>
          )
        })} 
      </div>
      ) : false }


      { phase === phaseOptions.WRITING ? (
        <div>
          <h2>The category is {category}</h2>
          <p>Write your answer in the box below and press send when you're done</p>
          {sentAnswer ? (
            <p>You sent: {sentAnswer}</p>
          ) : false}
          <textarea className="answer-box" value={answer} onChange={(e)=>{setAnswer(e.target.value)}}></textarea>
          <button onClick={()=>{
            sendSubmissionMessage({payload: { answer, userId }});
            setSentAnswer(answer);
            }} className="button-primary button-primary--space">Send</button>
        </div>
      ) : false}

      <GameCard card={card} withAnswers={false}/>
    </div>
  )
}
