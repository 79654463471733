import React, {useContext, useEffect, useState} from 'react'
import { PeerContext, VideoContext } from "../../contexts";
import useChannel from "../../helpers/Websockets";
import Leader from "./leader";
import Player from "./player";

export default function Game() {
  const [gameMessage, sendGameMessage] = useChannel("gameadmin");
  const [cardMessage, sendCardMessage] = useChannel("cards");
  const [leader, setLeader] = useState(null);
  const [card, setCard] = useState(null);
  
  const {     
    peers,
    userId,
  } = useContext(PeerContext);

  function getNewLeader(){
    sendGameMessage({
      type: "selectNewLeader",
      payload: {}
    });
  }

  function getCurrentCard(){
    sendCardMessage({
      type: "getCurrentCard",
      payload: {}
    });
  }

  function getNextCard(){
    sendCardMessage({
      type: "getNextCard",
      payload: {}
    });
  }

  function getPrevCard(){
    sendCardMessage({
      type: "getNextCard",
      payload: {
        moveBy: -1,
      }
    });
  }

  function getCurrentLeader(){
    sendGameMessage({
      type: "getCurrentLeader",
      payload: {}
    });
  }

  function calculatePoints(allGuesses) {
    const points = { };
    points[leader] = 0;
    const peerIds = Object.keys( allGuesses )
    peerIds.forEach( peerId => {
      if ( typeof points[peerId] !== "number" ) points[peerId] = 0;
    });
    let someoneGuessedCorrectly = false;
    peerIds.forEach( peerId => {
      const peerGuesses = allGuesses[peerId];
      if ( peerGuesses ) {
        const guessKeys = Object.keys( peerGuesses);
        guessKeys.forEach( author => {
          const guess = peerGuesses[author];
          if ( guess === author ){
            points[peerId]++;
            // 1 point for correct guess of someone else
            if ( guess === "real" ) {
              // 2 points for correct guess of the answer ( add another point )
              points[peerId]++;
              someoneGuessedCorrectly = true;
            }
          } else {
            if ( guess === "real" ) {
              // 1 point if someone guesses you are the real one
              points[author]++;
            }
          }
        })
      }
    })
    if ( !someoneGuessedCorrectly ) {
      // 3 points to leader if nobody guesses it
      points[leader] += 3;
    }
    return points;
  }

  useEffect(()=>{
    if ( gameMessage && (gameMessage.type === "selectNewLeader" || gameMessage.type === "getCurrentLeader")  ) {
      setLeader( gameMessage.payload.target );
    }
  },[gameMessage])

  useEffect(()=>{
    if ( cardMessage && (cardMessage.type === "getNextCard" || cardMessage.type === "getCurrentCard")  ) {
      setCard( cardMessage.payload.card );
    }
  },[cardMessage])

  useEffect(()=>{
    getCurrentLeader();
    getCurrentCard();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  if ( !leader ) {
    return( 
      <button className="button-primary" onClick={getNewLeader}>Start game!</button>
    )
  }

  let leaderName = peers && peers[leader] && peers[leader].friendly_name;

  return (
    <div>
      {userId === leader ? <Leader key={leader} calculatePoints={calculatePoints} passLeadership={getNewLeader} getNextCard={getNextCard} card={card} getPrevCard={getPrevCard} userId={userId} peers={peers}/> : <Player calculatePoints={calculatePoints} key={`${leader}-${userId}`} card={card} leaderName={leaderName} leader={leader} userId={userId} peers={peers}/>}
    </div>
  )
}
