import React, { Component, useState, useEffect } from 'react';
import { BrowserRouter, Route, Link, Switch } from 'react-router-dom';
import logo from './logo.svg';
import './App.scss';
import { Main } from "./components/main"
import { PeerContextProvider, VideoContextProvider, ConfigContextProvider } from "./contexts";
import useChannel from "./helpers/Websockets";

function App() {
  return (
    <ConfigContextProvider>
      <VideoContextProvider>
        <Main />
      </VideoContextProvider>
    </ConfigContextProvider>
  )
}

export default App;
