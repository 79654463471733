import React, { useContext, useState, useEffect, createContext } from "react";

const VideoContext = createContext();


function VideoContextProvider( props ) {

  const [VideoStream, setVideoStream] = useState(null);

  function recreateMediaStream() {
    navigator.mediaDevices.getUserMedia({ video: { facingMode: "user" }, audio: true }).then( 
      function( stream ) {
        console.log( stream )
        setVideoStream( stream );
      }).catch( 
      function( error ) {
        alert("An error occurred! Cannot access the required type of media stream. Please refresh the page to try again. If this issue persists, please try a different browser / device (ideally Chrome on desktop)");
        console.error( error );
      })
  }

  useEffect( () => {
    recreateMediaStream();
  },[])

  const defaultContext = {
    VideoStream,
    recreateMediaStream,
  };

  return (
    <VideoContext.Provider value={defaultContext}>
      { props.children }
    </VideoContext.Provider>
  );
}

export {
  VideoContext,
  VideoContextProvider,
}