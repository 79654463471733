import Cookies from "js-cookie";

function save( name, data ){
  Cookies.set(name, JSON.stringify(data), {
    sameSite: "strict",
  } );
}

function retrieve( name ){
  let data = Cookies.get(name);
  if ( data ) {
    return JSON.parse( data );
  } else {
    return null;
  }
}

export default { 
  save, 
  retrieve,
}