const Status = {
  stopped: 0,
  idle: 1,
  running: 2,
}

const Errors = {
  PushTypeError: new Error("Can only push functions to queue!"),
}


export class Queue {
  constructor( options ) {
    this.queue = [];
    this.status = Status.stopped;
    this.ErrorTypes = Errors;
    this.StatusTypes = Status;
    this.options = options;

    this.tick = this.tick.bind(this);
    this.run = this.run.bind(this);
    this.push = this.push.bind(this);
  }

  push( func ) {
    if ( typeof func !== "function" ) throw Errors.PushTypeError;
    let cancelled = false;
    let hasRun = false;
    function cancel() {
      if ( hasRun ) return false;
      cancelled = true;
      return true;
    }
    function run(){
      if ( cancelled ) return false;
      func();
      return true;
    }
    this.queue.push( run );
    if ( this.status === Status.idle ) {
      this.run();
    }
    return cancel;
  } 

  start() {
    if ( this.queue.length === 0 ) {
      this.status = Status.idle;
    } else {
      this.run();
    }
  }

  stop() {
    this.status = Status.stopped;
  }

  run() {
    this.status = Status.running;
    setImmediate(this.tick);
  }

  tick() {
    if ( this.status !== Status.running ) return;
    if ( this.queue.length === 0 ) {
      if ( this.options.stopOnIdle ) {
        this.status = Status.stopped
      } else {
        this.status = Status.idle;
      }
      return;
    }
    const nextInQueue = this.queue.shift();
    nextInQueue();
    setImmediate(this.tick);
  }

}