/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, createContext } from "react";
import Peer from "simple-peer";
import useChannel, { Channels, send, toEvent } from "../helpers/Websockets";
import getUserMedia from "../helpers/Video";
import Queue from "../helpers/Queue";


let userId;
let channelListener;
const { REACT_APP_BACKEND_HOST } = process.env;

const Status = {
  starting: 0,
  started: 1,
}


const PeerContext = createContext();

function PeerContextProvider( props ) {

  const [peers, setPeers] = useState({});
  const [peerStreams, setPeerStreams] = useState({});
  const [VideoStream, setVideoStream] = useState(null);
  const [offers, setOffers ] = useState([]);
  const [answers, setAnswers] = useState([]);

  const [participantPayload, getParticipants] = useChannel("participants");
  const [participants, setParticipants] = useState({});
  const [activity, _] = useChannel("useractivity");
  
  function refreshParticipants(){
    getParticipants({ payload: { friendly_name: props.name || `${Date.now()}` }});
  }

  useEffect( () => {
    if ( props.stream ) {
      setVideoStream( props.stream );
      refreshParticipants();
    } else {
      getUserMedia().then( stream => {
        setVideoStream( stream );
        refreshParticipants();
      });
    }
  },[])

  function message(id, message, type) {
    send(`client-channel-${id}`, {
      data: message,
      from: userId,
      for: id,
      friendly_name: props.name,
    }, type);
  }

  function handleMessage( message ) {
    if ( message.type === "offer" ) {
      setOffers( [...offers, message ]);
      // answerCall( from, data );
    } else if ( message.type === "answer" ) {
      setAnswers( [...answers, message ] );
      // acceptAnswer(from, data);
    }
  }

  function createPeer(id, initiator=false) {
    const peer = new Peer({ 
      initiator: initiator,
      trickle: false,
      stream: VideoStream,
    });
    peer.on('stream', (stream) => {
      const newStreams = {
        ...peerStreams,
      }
      newStreams[id] = stream;
      peer.VideoStream = stream;
      setPeerStreams( newStreams );
    });
    peer.on("signal", ( data ) => {
      if ( data.type === "answer") {
        message( id, data, "answer");
      } else if ( data.type === "offer") {
        message( id, data, "offer");
      }
    });

    function removeSelf(){
      let peerToClose = peers[id];
      if ( peerToClose ) {
        const newPeers = {
          ...peers,
        }
        delete newPeers[id];
        setPeers( newPeers );
      }
    }

    function destroySelf(){
      let peerToClose = peers[id];
      if ( peerToClose ) {
        peerToClose.destroy();
      }
    }

    peer.on("close", ()=>{
      destroySelf();
      removeSelf();
    })
    peer.on("error", (err)=>{
      console.log( `Peer RTC error with ${id}` );
      console.log( err );
      // destroySelf();
      // let deleteIds = {};
      // deleteIds[id] = true;
      // initCalls(deleteIds)
    })
    return peer;
  }

  function savePeer(peer, id) {
    const newPeers = {
      ...peers,
    }
    if ( newPeers[id] ) {
      try {
        newPeers[id].destroy();
      } catch( err ) {
        
      }
    }
    newPeers[id] = peer;
    setPeers( newPeers );
  }

  function callSomeone(id) {
    console.log("calling ", id)

    const peer = createPeer(id, true);
    return peer;
  }

  function answerCall(id, offer, friendly_name) {
    console.log("answering call answer from ", id)

    const peer = createPeer(id, false);
    if ( friendly_name ) {
      peer.friendly_name = friendly_name;
    }
    peer.signal(offer);
    savePeer( peer, id );
  }

  function acceptAnswer( id, answer, friendly_name) {
    console.log("accepting answer from ", id)
    const peer = peers[id];
    if ( peer ) {
      if ( friendly_name ) {
        peer.friendly_name = friendly_name;
      }
      peer.signal( answer );
    }
  }

  useEffect( () => {
    if ( offers.length > 0) {
      offers.forEach( offer => {
        const { from, data, friendly_name } = offer.payload;
        answerCall( from, data, friendly_name );
      })
      setOffers([]);
    }
  }, [offers])

  useEffect( () => {
    if ( answers.length > 0 ) {
      answers.forEach( answer => {
        const { from, data, friendly_name } = answer.payload;
        if ( peers[from] ) {
          acceptAnswer(from, data, friendly_name );
        }
      })
      setAnswers([]);
    }
  }, [answers])

  function initCalls( remove ){
    if ( !!VideoStream && participantPayload ) {
      if ( participantPayload.payload.yourId ) {
        userId = participantPayload.payload.yourId;
        console.log("MY ID IS ", userId);
        if ( !channelListener ) {
          channelListener = Channels.on(`client-channel-${userId}`, handleMessage,`client-channel-${userId}`)
        }
      }
      if ( participantPayload.payload.ids ) {
        const newPeers = {};
        Object.keys(participantPayload.payload.ids ).forEach( key => {
          const peerName = participantPayload.payload.ids[key];
          if ( remove && remove[key] ) return;
          if ( peers[key] ) {
            newPeers[key] = peers[key]
          } else if ( key !== userId  ) {
            newPeers[key] = callSomeone(key);
          }
        })
        setPeers( newPeers );
      }
    }
  }
  window.refreshCalls= initCalls

  useEffect( () => {
   initCalls();
  },[VideoStream, participantPayload])

  useEffect(()=>{
    if( activity && activity.type === "navigate" && activity.payload.exit ) {
      refreshParticipants();
    }
  },[activity])

  const defaultContext = {
    StatusOptions: Status,
    VideoStream,
    peers,
    peerStreams,
    userId,
  };

  return (
    <PeerContext.Provider value={defaultContext}>
      { props.children }
    </PeerContext.Provider>
  );
}

export {
  PeerContext,
  PeerContextProvider,
}


