import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Home } from "../home";
import { Welcome } from "../welcome";
import { PeerContextProvider, ConfigContext, VideoContext } from "../../contexts";

export function Main(props) {
  const { 
    name,
  } = useContext(ConfigContext);
  const { 
    VideoStream,
  } = useContext(VideoContext);

  const [showHome, setShowHome] = useState(false);

  function setShowHomeTrue() {
    setShowHome( true );
  }

  return (
    <div>
      <header>Absolute Balderdash!</header>
      { showHome && VideoStream ? (
        <PeerContextProvider name={name} stream={VideoStream}>
          <Home/> 
        </PeerContextProvider>
      ): <Welcome done={setShowHomeTrue}/> }
    </div>
  )
}

Main.propTypes = {

}
