import React, { useContext, useState, useEffect, createContext } from "react";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string'
import jwt from "jsonwebtoken";
import { Cookies, Queue } from "../helpers";
import axios from "axios";
const { REACT_APP_BACKEND_HOST } = process.env;
const { save, retrieve } = Cookies;

const Status = {
  starting: 0,
  logged_in: 1,
  logged_out: 2,
}

let pub_key;

const UserContext = createContext();

const RequestQueue = new Queue();
window.RQ = RequestQueue;
function resetPublicKey(){
  return axios.get(`${ REACT_APP_BACKEND_HOST }/api/auth/public_key`)
    .then( ( res ) => {
      if ( res.data.error ) {
        throw new Error( res.data.error );
      }
      return pub_key = res.data.data;
    })
}

function verify( token, data={} ) {
  return new Promise( ( resolve, reject ) => {
    jwt.verify(token, pub_key, {
      algorithms: ["RS256"]
    }, function(err, decoded) {
      if ( err ) {
        reject( err );
      } else {
        resolve( {
          decoded,
          ...data,
        } ); 
      }
    })
  })
}

function refresh( refresh_token ) {
  if ( !refresh_token ) {
    refresh_token = retrieve( "refresh" );
  }
  if ( !refresh_token ) {
    throw new Error("Cannot refresh without refresh token!");
  }
  return  axios.post(REACT_APP_BACKEND_HOST + "/api/auth/refresh",{
      token: refresh_token
    })
    .then( res => {
      const { data } = res;
      const { user, error } = data;
      if ( error ) throw new Error(error);
      return verify( user, data );
    })
    .then( data => {
      save( "user", data.user ); //save raw token
      save( "refresh", data.refresh_token ); //save refresh token
      return data;
    })
}

function initUser() {
  return new Promise( ( resolve, reject ) => {
    let defaultUser = retrieve( "user" );
    if ( !defaultUser ) {
      resolve( null );
    } else {
      verify( defaultUser )
        .then( resolve )
        .catch( reject );
    }
  })
    .catch( err => {
      if ( err.name === "TokenExpiredError" ) {
        return refresh();
      }
    })
    .then( data => data.decoded )
}

let userTimeout;
function UserContextProvider( props ) {

  let [ user, setUserValue ] = useState(null);

  function setUser( userValue ) {
    if ( userTimeout ) {
      clearTimeout( userTimeout );
    }
    let msToExpiry = userValue.exp*1000 - Date.now();
    let oneMin = 1000*60;
    userTimeout = setTimeout(( ) => {
      refresh()
        .then( data => {
          setUser( data.decoded );
        })
        .catch( err => {
          console.log( err );
        });
    }, msToExpiry - oneMin*1 )
    setUserValue( userValue );
  }

  function get(rawUrl, axiosOptions) {
    return new Promise( ( resolve, reject ) => {
      RequestQueue.push(() => {
        const config = {
          ...axiosOptions,
        }
        const token = retrieve("user")
        if ( token ) {
          Object.assign( config, {headers: { Authorization: `Bearer ${ token }` } } );
        }
        let url = rawUrl;
        if ( url.indexOf("http") !== 0 ){
          url = process.env.REACT_APP_BACKEND_HOST + url;
        }
        axios.get( url, config )
        .then( resolve )
        .catch( reject );
      })
    })
  }

  function post(rawUrl, data, axiosOptions={}) {
    return new Promise( ( resolve, reject ) => {
      RequestQueue.push(() => {
        const config = {
          ...axiosOptions,
        }
        const token = retrieve("user")
        if ( token ) {
          Object.assign( config, {headers: { Authorization: `Bearer ${ token }` } } );
        }
        let url = rawUrl;
        if ( url.indexOf("http") !== 0 ){
          url = process.env.REACT_APP_BACKEND_HOST + url;
        }
        axios.post( url, data, config )
          .then( resolve )
          .catch( reject );
      })
    })
  }

  function request( axiosOptions ) {
    return new Promise( ( resolve, reject ) => {
      const config = {
        ...axiosOptions,
      }
      const token = retrieve("user")
      if ( token ) {
        Object.assign( config, {headers: { Authorization: `Bearer ${ token }` } } );
      }
      RequestQueue.push(() => {
        axios.request( config )
        .then( resolve )
        .catch( reject );
      })
    })
  }


  let [ status, setStatus ] = useState(Status.starting);

  const defaultContext = {
    user,
    status,
    StatusOptions: Status,
    get,
    post,
    request,
  };

  let location = useLocation();
  useEffect( () => {
    RequestQueue.push( () => {
      let query = queryString.parse(location.search)
      if ( query.token ) {
        refresh( query.token )
          .then( data => {
            setUser( data.decoded );
            setStatus(Status.logged_in);
          })
          .catch( ( err ) => {
            console.log( err );
          } )
      }
    })
  } , [location])

  useEffect( () => {
    resetPublicKey()
      .then( initUser )
      .then( ( theUser ) => {
        setUser( theUser );
        setStatus(Status.logged_in);
      })
      .catch( ( err ) => {
        setStatus(Status.logged_out);
      })
  } , [])

  useEffect( ()=>{
    if ( status !== Status.starting ) {
      RequestQueue.start();
    } else {
      RequestQueue.stop();
    }
  }, [status])

  return (
    <UserContext.Provider value={defaultContext}>
      { props.children }
    </UserContext.Provider>
  );
}

export {
  UserContext,
  UserContextProvider,
}


