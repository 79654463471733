import React,{ useState, useContext, useRef } from 'react'
import PropTypes from 'prop-types'

import { ConfigContext, VideoContext } from "../../contexts";
import Streamer from "../streamer";
export function Welcome({ done }) {
  const { 
    name,
    saveName,
  } = useContext(ConfigContext);

  const { 
    VideoStream,
  } = useContext(VideoContext);

  const [hasLoggedInBefore, _] = useState(!!name);
  const nameInput = useRef();

  return (
    <div className="welcome__wrapper">
      <h1 className="welcome__header">
        { hasLoggedInBefore && name ? `Welcome back, ${name}` : "Ready to get started?"}
      </h1>
      <p className="welcome__intro">
         Check your video stream and your name below, and when you're happy click continue.
      </p>
      <label className="input-group">
        Name:
        <input ref={nameInput} className="input-group__text" type="text" value={name} onChange={ e => saveName(e.target.value)} broadcast={false}/>
      </label>
      <Streamer VideoStream={VideoStream} muted={true} />
      <button className={`button-primary ${!name? "disabled": ""}`}onClick={()=> {
        if ( !name && nameInput.current ) {
          nameInput.current.focus();
        } else {
          done()
        }
      }}>Continue</button>
    </div>
  )
}

Welcome.propTypes = {

}
